import * as React from "react";
import Header from "../components/headesLawyers";
import Inicio from "../components/LawyersContent";
import { Helmet } from "react-helmet";
// import "../styles/indexAbogados.css";
// markup

const IndexPage = () => {
  return (
    <div className="lawyersIndex">
      <Helmet>
        <meta charSet="utf-8" />
        <meta
          name="description"
          content="¡Participa ofreciendo tus contratos en formato digital a los
            mas de 60, 000 usuarios que tenemos en TRATO y recibe
            un ingreso Adicional!"
        />
        <title>¿Eres Abogado y te dedicas a la elaboración de contratos?</title>
      </Helmet>
      <Header />
      <Inicio />
    </div>
  );
};

export default IndexPage;
