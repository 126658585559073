import React, { useEffect, useState } from "react";
import "../scss/lawyersLanding/lawyerscontent.css";
import Textura1 from "../images/lawyersLanding/Textura.svg";
import Textura2 from "../images/lawyersLanding/Bg_Img.svg";
import Picture from "../images/lawyersLanding/Group 7333@2x.webp";
import Target from "../images/lawyersLanding/Target.svg";
import Boton from "../images/lawyersLanding/Boton.svg";
function Inicio() {
  const [isLoaded, setIsLoaded] = useState(false);
  useEffect(() => {
    setIsLoaded(true);
  }, []);

  return (
    <section className="lawyersIndex">
      <div className="inicio-div-1">
        <div className="inicio-info">
          <h1>
            ¿Eres Abogado <br /> y te dedicas a la elaboración <br /> de
            contratos?
          </h1>
          <p>
            ¡Participa ofreciendo tus contratos <br /> en formato digital a los
            mas de 60, 000 <br /> usuarios que tenemos en TRATO <br /> y recibe
            un ingreso Adicional!
          </p>

          <a href="https://lawyers.trato.io/" role="button">
            <div className="botonlawyres">
              <span>Ingresa</span>
            </div>
          </a>
        </div>
        <img src={Textura2} alt="Textura" className="textura2" />
        <img src={Textura1} alt="Textura" className="textura1" />
        <div className="pictureBoxLawyers">
          {isLoaded && (
            <img
              src={Picture}
              alt="Picture"
              className="pictureLawyers"
              loading="lazy"
              width={400}
              height={500}
            />
          )}
          <div className="marketingBoxLawyers">
            <div>
              <img src={Target} alt="Target" />
              <h3>Marketing</h3>
            </div>

            <p>
              Ademas con el marketing que se realice <br /> sobre tus plantillas
              podrás dar a conocer <br /> tu despacho
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Inicio;
