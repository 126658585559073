import React from "react";
import "../scss/lawyersLanding/headerlawyers.css";
import Logo from "../images/lawyersLanding/Logo Trato.svg";
function Header() {
  return (
    <header className="headerlawyers">
      <img src={Logo} alt="Logo" />
    </header>
  );
}

export default Header;
